
const MIN_LOAD_AMOUNT_IN_CENTS = 500;
const USER_DEFAULT_AVATAR_URL = '/images/user/placeholder-avatar.png';
const NONPROFIT_DEFAULT_IMG_URL = '/images/nonprofit/placeholder-avatar.png';

const URL_BLOG = 'https://blog.milliegiving.com';
const URL_SUPPORT = 'https://support.milliegiving.com';
const URL_PODCAST = 'https://blog.milliegiving.com/changemakers';
const URL_CAMPAIGN_CALENDAR = 'https://blog.milliegiving.com/the-2021-social-impact-and-csr-calendar/';

const COMPANY_BALANCE = {
  name: 'Company Wallet Balance',
  type: 'COMPANY_BALANCE',
};

/*
 *  ENUMS in Database
 *  Take care when editing! - These values are used in inclusion validators and stored in the database.
 *  If a change removes a value here, it likely needs a corresponding migration that updates values stored in DB.
 */

const Categories = {
  ANIMALS: 'animals',
  CIVIL_RIGHTS: 'civil-rights',
  COMMUNITY_DEV: 'community-dev',
  CULTURE: 'culture',
  EDUCATION: 'education',
  ENVIRONMENT: 'environment',
  HEALTH: 'health',
  HUMAN_SERVICES: 'human-services',
  INTERNATIONAL: 'international',
  OTHER: 'other',
  PUBLIC_POLICY: 'public-policy',
  RELIGION: 'religion',
  PUBLIC_SERVICES: 'public-services',
};

const ActionTypes = {
  WALLET_LOAD: 'wallet-load',
  PAYROLL_DEDUCTION: 'payroll-deduction',
  DONATION: 'donation',
  GIFT_SENT: 'gift-sent',
  GIFT_REDEEMED: 'gift-redeemed',
  EMPLOYER_MATCH: 'employer-match',
  EXPIRED_GIFT_RETURNED: 'expired-gift-returned',
};

const RecommendationReasons = {
  DISTANCE: 'distance',
  TAGS: 'tags',
  LAUNCH: 'launch',
  RANDOM: 'random',
  MIGRATED: 'migrated',
};

const NonprofitAdminStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const MobileHandoffTypes = {
  DONATE: 'donate',
  NAVIGATE: 'navigate',
};

const NonprofitBadges = {
  PROGRAMMING_PRO: 'programming-pro',
  ESTABLISHED: 'established',
  SOCIAL_BUTTERFLY: 'social-butterfly',
  IMPACTFUL_REACH: 'impactful-reach',
  STARTUP: 'startup',
  HOMETOWN_HERO: 'hometown-hero',
  SOLID_SUPPORT: 'solid-support',
};

const CompanyNonprofitApprovalStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  BANNED: 'banned',
};

const EmployeeStatuses = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

const EmployeeRoles = {
  EMPLOYEE: 'employee',
  ADMIN: 'admin',
  CHAMPION: 'champion',
  FINANCE: 'finance',
  GROUP_LEAD: 'groups',
};

const EmployeeDonationMatchStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
  EMPLOYEE_LIMIT_REACHED: 'employee-limit-reached',
  COMPANY_LIMIT_REACHED: 'company-limit-reached',
  NO_MATCH: 'no-match',
  INELIGIBLE: 'ineligible',
};

const CompanyNteeRuleTypes = {
  STARTS_WITH: 'starts-with',
  ENDS_WITH: 'ends-with',
};

const EmployeeDonationTypes = {
  DONATION: 'Donation',
  OFFLINE_DONATION: 'OfflineDonation',
};

const SocialFeedTypes = {
  COMPANY: 'Company',
  BRACKET: 'Bracket',
  GROUP: 'Group',
  EVENT: 'VolEvent',
  CAMPAIGN: 'Campaign',
};

const GiftExpirationTypes = {
  RETURN_TO_SENDER: 'return-to-sender',
};

const PaymentMethodTypes = {
  CREDIT_CARD: 'CreditCard',
  BANK_ACCOUNT: 'BankAccount',
};

const CompanyChargeReasons = {
  MATCH: 'match',
  GIFT: 'gift',
  GRANT: 'grant',
  WALLET_LOAD: 'wallet-load',
  PAYROLL: 'payroll',
  DFD: 'dfd',
  FEE_COVERAGE: 'fee-coverage',
};

const CompanyChargeErrorReasons = {
  PAYMENT_CONFIG: 'payment-config',
  CHARGE_FAILED: 'charge-failed',
  OTHER: 'other',
};

const CompanyPurchaseTypes = {
  MATCH: 'match',
  GIFT: 'gift',
  GRANT: 'grant',
  PAYROLL: 'payroll',
  DFD: 'dfd',
  FEE_COVERAGE: 'fee-coverage',
};

const EmailVerificationReasons = {
  NEW_USER: 'new-user',
  EMAIL_CHANGE: 'email-change',
  RESET_PW: 'reset-pw',
};

const RecurringDonationStatuses = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled',
};

const RecurringDonationPauseReasons = {
  NO_PAYMENT_PROFILE: 'no-payment-profile',
  PAYMENT_FAILED: 'payment-failed',
};

const CampaignStatuses = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  ENDED: 'ended',
};

const VolTimeEntryReviewStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const VolTimeEntryEntryTypes = {
  MANUAL: 'manual',
  RSVP: 'rsvp',
  BATCH: 'batch',
  ADMIN: 'admin',
};

const DisbursementCauses = {
  DONATION: 'donation',
  MATCH: 'match',
  GRANT: 'grant',
  FUND: 'fund',
  FEE_COVERAGE: 'fee-coverage',
};

const MatchInquiryStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const OfflineDonationStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const UserBalanceTypes = {
  MAIN: 'main',
  GIFT: 'gift',
  PAYROLL: 'payroll',
  INTL_MAIN: 'intl-main',
  INTL_GIFT: 'intl-gift',
  CC: 'credit-card',
};

const CompanyGiftTypes = {
  EMPLOYEE: 'employee',
  CUSTOMER: 'customer',
  DFD: 'dfd',
};

const GiftGiverTypes = {
  USER: 'User',
  COMPANY: 'Company',
};

const DonatableTypes = {
  NONPROFIT: 'Nonprofit',
  FUND: 'Fund',
};

const FundOwnerTypes = {
  CAMPAIGN: 'Campaign',
  BRACKET: 'Bracket',
};

const VolEventTypes = {
  VOL_OPP: 'vol-opp',
  EVENT: 'event',
};

// TODO: remove some of these
const BracketRoundDurationUnitTypes = {
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const BracketStatuses = {
  UPCOMING: 'upcoming',
  IN_PROGRESS: 'in-progress',
  COMPLETE: 'complete',
};

const BracketRoundStatuses = {
  UPCOMING: 'upcoming',
  IN_PROGRESS: 'in-progress',
  COMPLETE: 'complete',
};

const BalanceOwnerTypes = {
  COMPANY: 'Company',
  COMPANY_EXPIRED_GIFTS: 'CompanyExpiredGifts',
  USER: 'User',
  USER_GIFT: 'UserGift',
  FUND: 'Fund',
  PURCHASE: 'Purchase',
  GIFT: 'Gift',
  NONPROFIT: 'Nonprofit',
  ADJUSTMENTS: 'Adjustments',
  LEGACY_ADJUSTMENTS: 'LegacyAdjustments',
};

const BalanceTransactionCauseTypes = {
  WALLET_LOAD: 'WalletLoad',
  COMPANY_CHARGE: 'CompanyCharge',
  DISBURSEMENT: 'Disbursement',
  DONATION: 'Donation',
  EMPLOYEE_DONATION: 'EmployeeDonation',
  FEE_COVERAGE: 'FeeCoverage',
  GIFT_SEND: 'GiftSend',
  GIFT_REDEEM: 'GiftRedeem',
  GIFT_EXPIRE: 'GiftExpire',
  GRANT: 'Grant',
  FUND_DISBURSEMENT: 'FundDisbursement',
  TRANSFER: 'Transfer',
  PURCHASE: 'Purchase',
  RECONCILE: 'Reconcile',
  PAYROLL_DEDUCTION: 'PayrollDeduction',
  ADJUSTMENT: 'Adjustment',
};

const BudgetSpendDiscrepancyReasons = {
  BUDGET_CAP: 'budget-cap',
  EMPLOYEE_CAP: 'employee-cap',
  AMBIGUOUS_CAP: 'ambiguous-cap',
};

const BudgetSpendCauses = {
  EMPLOYEE_DONATION: 'EmployeeDonation',
  VOL_TIME_ENTRY: 'VolTimeEntry',
  FEE_COVERAGE: 'FeeCoverage',
};

const DisputeChargeTypes = {
  WALLET_LOAD: 'WalletLoad',
  COMPANY_CHARGE: 'CompanyCharge',
};

const HappyEventTypes = {
  CAMPAIGN_COMPLETED: 'campaign-completed',
  EVENT_COMPLETED: 'event-completed',
  BRACKET_COMPLETED: 'bracket-completed',
  GROUP_CREATED: 'group-created',
  GRANT_SENT: 'grant-sent',
  DTD_RECEIVED: 'dtd-received'
};

const EngagementTypes = {
  ENGAGEMENT: 'engagement',
  GROUP_JOIN: 'group-join',
  BRACKET_VOTE: 'bracket-vote',
  EVENT_REGISTRATION: 'event-registration',
  VOL_EVENT_REGISTRATION: 'vol-event-registration',
  DONATION: 'donation',
  VOL_TIME_ENTRY: 'vol-time-entry',
  ACCOUNT_ACTIVATION: 'account-activation',
  ACCOUNT_DEACTIVATION: 'account-deactivation',
};

const NonprofitDatasetSources = {
  PROPUBLICA: 'propublica',
  CANDID: 'candid',
};

const DriveGoalTypes = {
  MONETARY: 'monetary',
  QUANTITY: 'quantity',
};

const Notifications = {
  ADMIN_MATCH_REQUEST: 'admin-match-request',
  ADMIN_OFF_DON_REQUEST: 'admin-off-don-request',
  ADMIN_MATCH_INQUIRY: 'admin-match-inquiry',
  EMP_MATCH_RESPONSE: 'emp-match-response',
  EMP_MATCH_INQUIRY_RESPONSE: 'emp-match-inquiry-response',
  EMP_DON_THANKYOU: 'emp-don-thankyou',
  EMP_GIFT: 'emp-gift',
  CAMP_DONATION: 'camp-donation',
  CAMP_THRESHOLD: 'camp-threshold',
  CAMP_START: 'camp-start',
  CAMP_REMINDER: 'camp-reminder',
  CAMP_RESULTS: 'camp-results',
  COMPANY_MONTHLY_UPDATE: 'company-monthly-update',
};

const HappeningTypes = {
  CAMPAIGN: 'campaign',
  DRIVE: 'drive',
  VOL_EVENT: 'vol-opp',
  GROUP_EVENT: 'event',
  BRACKET: 'bracket',
};

const HappeningStatuses = {
  ENDED: 'ended',
  ACTIVE: 'active',
  UPCOMING: 'upcoming',
  RECENT: 'recent',
};

const NonprofitIneligibleReasons = {
  NOT_IN_BMF: 'not-in-bmf',
  NOT_501C3: 'not-501c3',
  AUTO_REVOKED: 'auto-revoked',
  MILLIE_OVERRIDE: 'millie-override',
};

const IrsDeductibilityCodes = {
  DEDUCTIBLE: '1',
  NOT_DEDUCTIBLE: '2',
  DEDUCTIBLE_BY_TREATY: '4',
};

const NonprofitControlEligibleOverrideTypes = {
  TEMPORARY: 'temporary',
  INDEFINITE: 'indefinite',
};

const BackstageRoles = {
  SUPER: 'super',
  NONPROFIT_DATA: 'nonprofit-data',
};

const NonprofitBuildStatuses = {
  PENDING: 'pending',
  BLOCKED: 'blocked',
  DONE: 'done',
};

const VolEventTags = {
  REMOTE: 'remote',
  IN_PERSON: 'in-person',
  FOR_GROUPS: 'for-groups',
  FOR_INDIVIDUALS: 'for-individuals',
  ONE_TIME: 'one-time',
  COMMITMENT: 'commitment',
  SKILLS_BASED: 'skills-based',
};

const CattrEmployeeSelectionTypes = {
  DISALLOWED: 'disallowed',
  ALLOWED: 'allowed',
  REQUIRED: 'required',
};

const SocialReactionTargets = {
  POST: 'SocialPost',
  COMMENT: 'SocialPostComment',
};

const CompanyCustomerTypes = {
  CUSTOMER: 'customer',
  FRIENDS: 'friends',
  TEST: 'test',
};

const PercentPaymentStatuses = {
  IN_TRANSIT: 'in_transit',
  PAID: 'paid',
};

const PercentPaymentMethods = {
  CHECK: 'cheque',
  CARD: 'card',
  BANK: 'bank_transfer',
};

/*
 *  Other stuff
 */

const Pages = {
  /*-- App Pages --*/
  NOT_FOUND: '404-not-found',
  FORBIDDEN: '403-forbidden',
  ERROR: 'error',
  APPROVAL_REQUIRED: 'approval-required',
  LOGIN: 'login',
  SSO_LOGIN: 'sso-login',
  SIGNUP: 'signup',
  SIGNUP_EMP: 'signup-emp',
  SIGNUP_IND: 'signup-ind',
  FORGOT_PASSWORD: 'forgot-password',
  RESEND_EMP_INVITE: 'resend-emp-invite',
  '2FA': '2fa',
  STYLE_GUIDE: 'style-guide',
  VERIFY_EMAIL: 'verify-email',
  EMAIL_VERIFICATION: 'email-verification',
  COLLECTION: 'collection',
  NTEE_CODES: 'ntee-codes',
  NONPROFITS: 'nonprofits',
  NONPROFIT_DASHBOARD: 'nonprofit-dashboard',
  NONPROFIT_PROFILE: 'nonprofit-profile',
  NONPROFIT_PROFILE_EDIT: 'nonprofit-profile-edit',
  NONPROFIT_PROFILE_PREVIEW: 'nonprofit-profile-preview',
  CREDIT_CARDS: 'credit-cards',
  WALLET: 'wallet',
  OFFLINE_DONATIONS_NEW: 'offline-donations-new',
  OFFLINE_DONATIONS_SHOW: 'offline-donations-show',
  CLAIM: 'claim',
  GIFTS_SEND: 'gifts-send',
  GIFTS_REDEEM: 'gifts-redeem',
  USER_PROFILE: 'user-profile',
  ACCOUNT: 'account',
  NEW_PASSWORD: 'new-password',
  SLACK_CONNECT_NONCE: 'slack-connect-nonce',
  EMPLOYEE_INVITE: 'employee-invite',
  CAMPAIGN: 'campaign',
  VOL_EVENT: 'vol-event',
  VOLUNTEER: 'volunteer',
  VOLUNTEER_ME: 'volunteer-me',
  HAPPENINGS: 'happenings',
  GROUPS: 'groups',
  GROUP: 'group',
  BRACKET: 'bracket',
  BRACKET_GAME: 'bracket-game',
  BRACKET_BUILDER: 'bracket-builder',
  INTL_ORGS_SEARCH: 'intl-orgs-search',
  EVERY_CENT: 'every-cent',
  ONBOARDING: 'onboarding',
  FEED: 'feed',
  SOCIAL_POST: 'social-post',
  /*-- Simple Pages --*/
  BROWSER_NOT_SUPPORTED: 'no-ie',
  /*-- Company Admin --*/
  COMPANY_DASHBOARD: 'company-dashboard',
  CADMIN_SLACK_SETTINGS: 'cadmin-slack-settings',
  CADMIN_DASHBOARD: 'cadmin-dashboard',
  CADMIN_EMPLOYEES: 'cadmin-employees',
  CADMIN_EMPLOYEES_UPLOAD: 'cadmin-employees-upload',
  CADMIN_EMPLOYEE: 'cadmin-employee',
  CADMIN_MATCH_SETTINGS: 'cadmin-match-settings',
  CADMIN_MATCHES: 'cadmin-matches',
  CADMIN_MATCH_INQUIRIES: 'cadmin-match-inquiries',
  CADMIN_OFFLINE_DONATIONS: 'cadmin-off-dons',
  CADMIN_WALLET: 'cadmin-wallet',
  CADMIN_TRANSACTIONS2: 'cadmin-transactions2',
  CADMIN_PURCHASE: 'cadmin-purchase',
  CADMIN_DASHBOARD_EDIT: 'cadmin-dashboard-edit',
  CADMIN_VOL_EVENTS: 'cadmin-vol-events',
  CADMIN_VOL_ANALYTICS: 'cadmin-vol-analytics',
  CADMIN_VOL_TRACKING: 'cadmin-vol-tracking',
  CADMIN_VOL_DFD: 'cadmin-vol-dfd',
  CADMIN_EDIT_VOL_EVENT: 'cadmin-edit-vol-event',
  CADMIN_NEW_VOL_EVENT: 'cadmin-new-vol-event',
  CADMIN_CAMPAIGNS: 'cadmin-campaigns',
  CADMIN_NEW_CAMPAIGN: 'cadmin-new-campaign',
  CADMIN_EDIT_CAMPAIGN: 'cadmin-edit-campaign',
  CADMIN_CAMPAIGNS_TEMPLATES: 'cadmin-campaigns-templates',
  CADMIN_DRIVE_DONATIONS: 'cadmin-drive-donations',
  CADMIN_GIFTS: 'cadmin-gifts',
  CADMIN_GIFTS_NEW: 'cadmin-gifts-new',
  CADMIN_SSO: 'cadmin-sso',
  CADMIN_GRANTS: 'cadmin-grants',
  CADMIN_GRANTS_CREATE: 'cadmin-grants-create',
  CADMIN_GROUPS: 'cadmin-groups',
  CADMIN_GROUP_EMPLOYEES: 'cadmin-group-employees',
  CADMIN_GROUPS_NEW: 'cadmin-groups-new',
  CADMIN_GROUPS_EDIT: 'cadmin-groups-edit',
  CADMIN_GROUPS_SETTINGS: 'cadmin-groups-settings',
  CADMIN_PAYROLL: 'cadmin-payroll',
  CADMIN_BRACKETS: 'cadmin-brackets',
  CADMIN_BRACKETS_NEW: 'cadmin-brackets-new',
  CADMIN_BRACKETS_EDIT: 'cadmin-brackets-edit',
  CADMIN_BRACKETS_DASHBOARD: 'cadmin-brackets-dashboard',
  CADMIN_SUBSCRIPTION: 'cadmin-subscription',
  CADMIN_BUDGETS: 'cadmin-budgets',
  CADMIN_STATS: 'cadmin-stats',
  CADMIN_STATS2: 'cadmin-stats2',
  CADMIN_JUNK_DRAWER: 'cadmin-junk-drawer',
  CADMIN_CARDS: 'cadmin-cards',
  CADMIN_NTEE_CODES: 'cadmin-ntee-codes',
  CADMIN_NONPROFITS: 'cadmin-nonprofits',
  CADMIN_CATTRS: 'cadmin-cattrs',
  CADMIN_CHARGE_ERRORS: 'cadmin-charge-errors',
  CADMIN_CHARGE_ERROR: 'cadmin-charge-error',
  /*-- Backstage --*/
  BS_INDEX: 'bs-index',
  BS_PENDING_CLAIMS: 'bs-pending-claims',
  BS_USERS: 'bs-users',
  BS_USER: 'bs-user',
  BS_COLLECTIONS: 'bs-collections',
  BS_NONPROFITS: 'bs-nonprofits',
  BS_NONPROFIT: 'bs-nonprofit',
  BS_COMPANIES: 'bs-companies',
  BS_COMPANY: 'bs-company',
  BS_CARDS: 'bs-cards',
  BS_DASHBOARD_OVERVIEW: 'bs-dashboard-overview',
  BS_FFS: 'bs-feature-flags',
  BS_PRICING: 'bs-pricing',
  BS_FAILED_CHARGES: 'bs-failed-charges',
  BS_CONFIG: 'bs-config',
  BS_BALANCE: 'bs-balance',
  BS_BALANCES: 'bs-balances',
  BS_BALANCES_TRANSFER: 'bs-balances-transfer',
  BS_NTEE_CODES: 'bs-ntee-codes',
  BS_NONPROFIT_REQUESTS: 'bs-nonprofit-requests',
  BS_DISBURSEMENTS: 'bs-disbursements',
  BS_DISBURSEMENT: 'bs-disbursement',
};

const NavItems = {
  COMPANIES: 'companies',
  NONPROFITS: 'nonprofits',
  GIFT: 'gift',
  FEED: 'feed',
  WALLET: 'wallet',
  VOLUNTEER: 'volunteer',
  EVENTS: 'events',
  GROUPS: 'groups',
  MADNESS: 'madness',
};

const VolEventTagNames = {
  [VolEventTags.REMOTE]: 'Remote',
  [VolEventTags.IN_PERSON]: 'In-Person',
  [VolEventTags.FOR_GROUPS]: 'Great for Groups',
  [VolEventTags.FOR_INDIVIDUALS]: 'Great for Individuals',
  [VolEventTags.ONE_TIME]: 'One Time',
  [VolEventTags.COMMITMENT]: 'Repeat Engagement',
  [VolEventTags.SKILLS_BASED]: 'Skills-Based',
};

const VolEventTagIcons = {
  [VolEventTags.REMOTE]: 'WifiSignal4',
  [VolEventTags.IN_PERSON]: 'Pin',
  [VolEventTags.FOR_GROUPS]: 'EscalatorPeople',
  [VolEventTags.FOR_INDIVIDUALS]: 'FaceId10',
  [VolEventTags.ONE_TIME]: 'CalendarDate',
  [VolEventTags.COMMITMENT]: 'SynchronizeArrows1',
  [VolEventTags.SKILLS_BASED]: 'MartialArtsKarate',
};

const CategoryNames = {
  [Categories.CULTURE]: 'Arts & Culture',
  [Categories.EDUCATION]: 'Education',
  [Categories.ENVIRONMENT]: 'Environment',
  [Categories.ANIMALS]: 'Animals',
  [Categories.HEALTH]: 'Health',
  [Categories.CIVIL_RIGHTS]: 'Civil Rights',
  [Categories.HUMAN_SERVICES]: 'Human Services',
  [Categories.INTERNATIONAL]: 'International',
  [Categories.COMMUNITY_DEV]: 'Community',
  [Categories.PUBLIC_POLICY]: 'Science',
  [Categories.RELIGION]: 'Religion',
  [Categories.OTHER]: 'Other',
  [Categories.PUBLIC_SERVICES]: 'Public Services',
};

const NonprofitBadgeNames = {
  [NonprofitBadges.PROGRAMMING_PRO]: 'Programming Pro',
  [NonprofitBadges.ESTABLISHED]: 'Established',
  [NonprofitBadges.SOCIAL_BUTTERFLY]: 'Social Butterfly',
  [NonprofitBadges.IMPACTFUL_REACH]: 'Impactful Reach',
  [NonprofitBadges.STARTUP]: 'Startup',
  [NonprofitBadges.HOMETOWN_HERO]: 'Hometown Hero',
  [NonprofitBadges.SOLID_SUPPORT]: 'Solid Support',
};

const CompanyNonprofitApprovalStatusNames = {
  [CompanyNonprofitApprovalStatuses.PENDING]: 'Pending',
  [CompanyNonprofitApprovalStatuses.APPROVED]: 'Approved',
  [CompanyNonprofitApprovalStatuses.BANNED]: 'Restricted',
};

const EmployeeRoleLabels = {
  [EmployeeRoles.EMPLOYEE]: 'Employee',
  [EmployeeRoles.ADMIN]: 'All-Access Admin',
  [EmployeeRoles.FINANCE]: 'Finance Team',
  [EmployeeRoles.CHAMPION]: 'Impact Champion',
  [EmployeeRoles.GROUP_LEAD]: 'Group Lead',
};

const CommonTimezones = [
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Denver',
  'America/Mexico_City',
  'America/Regina',
  'America/Chicago',
  'America/New_York',
  'America/Halifax',
  'Europe/London',
  'Europe/Berlin',
  'Europe/Kiev',
  'Asia/Jerusalem',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Tokyo',
  'Australia/Perth',
  'Australia/Darwin',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Sydney',
  'Pacific/Honolulu',
  'UTC',
];

const DonationFreqTypes = {
  ONE_OFF: 'one-off',
  RECURRING: 'recurring',
};

const VolEventStatuses = {
  LIVE: 'live',
  ENDED: 'ended',
};

const CAMP_DEMO_A = [
  'e5fb2990-ab7f-40e5-881a-c6b0e0a0bb70', // DEV new without template
  '107a1420-3620-4402-83ae-2a16ad534d9b', // PROD COVID-19 Relief Campaign
];

const CAMP_DEMO_B = [
  '9bb7f035-506b-413b-9c81-e1c668fd32cb', // DEV Cool Cat
  '9841c0e1-b8df-45ed-9da0-a99ac899b3d2', // PROD Climate Week
];

const DndTypes = {
  GM_NONPROFIT: 'gm-nonprofit',
};

const StripeInvoiceStatuses = {
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
};

const StripeSubscriptionStatuses = {
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
};

const Features = {
  MADNESS: 'madness',
  MATCH: 'match',
  GIFT: 'gift',
  GRANT: 'grant',
  VOL: 'vol',
  GROUPS: 'groups',
  SSO: 'sso',
  PAYROLL: 'payroll',
  DRIVE: 'drive',
  INTERNATIONAL: 'international',
};

export {
  MIN_LOAD_AMOUNT_IN_CENTS,
  USER_DEFAULT_AVATAR_URL,
  NONPROFIT_DEFAULT_IMG_URL,
  URL_BLOG,
  URL_SUPPORT,
  URL_PODCAST,
  URL_CAMPAIGN_CALENDAR,
  COMPANY_BALANCE,
  Categories,
  ActionTypes,
  RecommendationReasons,
  NonprofitAdminStatuses,
  MobileHandoffTypes,
  NonprofitBadges,
  CompanyNonprofitApprovalStatuses,
  EmployeeStatuses,
  EmployeeRoles,
  EmployeeDonationMatchStatuses,
  CompanyNteeRuleTypes,
  EmployeeDonationTypes,
  SocialFeedTypes,
  GiftExpirationTypes,
  PaymentMethodTypes,
  CompanyChargeReasons,
  CompanyChargeErrorReasons,
  CompanyPurchaseTypes,
  EmailVerificationReasons,
  RecurringDonationStatuses,
  RecurringDonationPauseReasons,
  CampaignStatuses,
  VolTimeEntryReviewStatuses,
  VolTimeEntryEntryTypes,
  DisbursementCauses,
  MatchInquiryStatuses,
  OfflineDonationStatuses,
  UserBalanceTypes,
  CompanyGiftTypes,
  GiftGiverTypes,
  DonatableTypes,
  FundOwnerTypes,
  VolEventTypes,
  BracketRoundDurationUnitTypes,
  BracketStatuses,
  BracketRoundStatuses,
  BalanceOwnerTypes,
  BalanceTransactionCauseTypes,
  BudgetSpendDiscrepancyReasons,
  BudgetSpendCauses,
  DisputeChargeTypes,
  HappyEventTypes,
  EngagementTypes,
  NonprofitDatasetSources,
  DriveGoalTypes,
  Notifications,
  HappeningTypes,
  HappeningStatuses,
  NonprofitIneligibleReasons,
  IrsDeductibilityCodes,
  NonprofitControlEligibleOverrideTypes,
  BackstageRoles,
  NonprofitBuildStatuses,
  VolEventTags,
  CattrEmployeeSelectionTypes,
  SocialReactionTargets,
  CompanyCustomerTypes,
  PercentPaymentStatuses,
  PercentPaymentMethods,
  Pages,
  NavItems,
  VolEventTagNames,
  VolEventTagIcons,
  CategoryNames,
  NonprofitBadgeNames,
  CompanyNonprofitApprovalStatusNames,
  EmployeeRoleLabels,
  CommonTimezones,
  DonationFreqTypes,
  VolEventStatuses,
  CAMP_DEMO_A,
  CAMP_DEMO_B,
  DndTypes,
  StripeInvoiceStatuses,
  StripeSubscriptionStatuses,
  Features,
};
